import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { uploadVideo } from "../../redux/actions/dashboardActions";
import { Box, LinearProgress, Typography, Button, CircularProgress } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const MediaRecorders = ({ uploadVideo, auth }) => {
  const { user } = auth;
  const [disableRecord, setDisableRecord] = useState(true);
  const [disablePlay, setDisablePlay] = useState(true);
  const [disableDownload, setDisableDownload] = useState(true);
  const [showRecorded, setShowRecorded] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [record, setRecord] = useState("Start Recording");
  const [webcam, setWebcam] = useState("Enable Camera");
  const [recordedBlobs, setRecordedBlobs] = useState([]);
  const [webStream, setWebStream] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [countdown, setCountdown] = useState(70);
  const [timerId, setTimerId] = useState(null);
  const videoRef = useRef(null);
  const recordedVideoRef = useRef(null);
  const errorMsgRef = useRef(null);
  const recordButtonRef = useRef(null);
  const playButtonRef = useRef(null);
  const downloadButtonRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    if (recordButtonRef.current) {
      recordButtonRef.current.textContent = record;
    }
  }, [record]);

  useEffect(() => {
    if (isRecording) {
      const timer = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
      setTimerId(timer);

      const timeout = setTimeout(() => {
        stopRecording();
      }, 70000); // Stop recording

      return () => {
        clearInterval(timer);
        clearTimeout(timeout);
      };
    } else {
      setCountdown(70); 
    }
  }, [isRecording]);

  const startCamera = async () => {
    const constraints = {
      audio: true,
      video: {
        width: 1280,
        height: 720,
      },
    };
    if (webcam === "Enable Camera") {
      setWebcam("Disable Camera");
      try {
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        setWebStream(stream);
        handleSuccess(stream);
      } catch (e) {
        console.error("navigator.getUserMedia error:", e);
      }
    } else if (webcam === "Disable Camera") {
      setWebcam("Enable Camera");
      handleStop(webStream);
    }
  };

  const handleSuccess = (stream) => {
    setDisableRecord(false);
    console.log("getUserMedia() got stream:", stream);
    window.stream = stream;
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
      videoRef.current.play();
    }
  };

  const handleStop = (stream) => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      setDisableRecord(true);
      setDisablePlay(true);
      setDisableDownload(true);
    }
  };

  const recordVideo = () => {
    if (record === "Start Recording") {
      setRecord("Stop Recording");
      setIsRecording(true);
      
      setRecordedBlobs([]);
      setShowRecorded(false);

      startRecording();
    } else {
      setRecord("Start Recording");
      setIsRecording(false);
      setDisablePlay(false);
      setDisableDownload(false);
      stopRecording();
    }
  };

  const startRecording = () => {
    let mediaRecorder = null;
    let recordedBlobs = [];
    let options = { mimeType: "video/webm;codecs=vp9,opus" };
    try {
      mediaRecorder = new MediaRecorder(window.stream, options);
    } catch (e) {
      console.error("Exception while creating MediaRecorder:", e);
      if (errorMsgRef.current) {
        errorMsgRef.current.innerHTML = `Exception while creating MediaRecorder: ${JSON.stringify(e)}`;
      }
      return;
    }

    console.log("Created MediaRecorder", mediaRecorder, "with options", options);
    setDisablePlay(true);
    setShowRecorded(false);
    setDisableDownload(true);
    mediaRecorder.onstop = (event) => {
      console.log("Recorder stopped: ", event);
      console.log("Recorded Blobs: ", recordedBlobs);
    };
    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.start();
    console.log("MediaRecorder started", mediaRecorder);
    setRecorder(mediaRecorder);
  };

  const handleDataAvailable = (event) => {
    if (event.data && event.data.size > 0) {
      setRecordedBlobs(prev => [...prev, event.data]);
    }
  };

  const stopRecording = () => {
    if (recorder) {
      recorder.stop();
    }
    setIsRecording(false);
    setDisablePlay(false);
    setDisableDownload(false);
    clearInterval(timerId); // Clear the countdown timer
  };

  const playRecorded = () => {
    const superBuffer = new Blob(recordedBlobs, { type: "video/webm" });
    if (recordedVideoRef.current) {
      recordedVideoRef.current.src = URL.createObjectURL(superBuffer);
      recordedVideoRef.current.controls = true;
      recordedVideoRef.current.play();
    }
    setShowRecorded(true);
  };

  const downloadVideo = () => {
    const blob = new Blob(recordedBlobs, { type: "video/mp4" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    const formData = new FormData();
    formData.append("video", blob);
    formData.append("userId", user._id);

    setIsUploading(true);
    uploadVideo(formData, setUploadProgress, setIsUploading);
  };

  return (
    <>
      <div id="container">
        <div className="buttonContainer">
          <Button id="start" onClick={startCamera}>
            {webcam}
          </Button>
          <Button id="record" disabled={disableRecord} onClick={recordVideo} ref={recordButtonRef}>
            {record}
          </Button>
          <Button id="play" disabled={disablePlay} onClick={playRecorded} ref={playButtonRef}>
            Play
          </Button>
          <Button id="download" disabled={disableDownload} onClick={downloadVideo} ref={downloadButtonRef}>
            Submit
          </Button>
        </div>
        <div>
          {isRecording && (
            <Typography variant='body2' sx={{ marginBottom: 15 }}>
              Time left: {Math.floor(countdown / 60)}:{("0" + (countdown % 60)).slice(-2)}
            </Typography>
          )}
          {isUploading ? (
            <Box>
              <CircularProgress />
              <Typography variant='body1' sx={{ marginBottom: 15 }}>{uploadProgress !== 100 ? 'Uploading' : 'Processing'}</Typography>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>
          ) : (
            <>
              <video
                id="gum"
                ref={videoRef}
                style={{ display: showRecorded ? "none" : "block" }}
                playsInline
                autoPlay
                muted
              ></video>
              <video
                id="recorded"
                ref={recordedVideoRef}
                style={{ display: showRecorded ? "block" : "none" }}
                playsInline
              ></video>
              {isRecording && (
                <FiberManualRecordIcon
                  style={{
                    color: 'red',
                    fontSize: 50,
                    position: 'absolute',
                    top: 10,
                    right: 10
                  }}
                />
              )}
            </>
          )}
        </div>
        <div>
          <span ref={errorMsgRef}></span>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = { uploadVideo };

export default connect(mapStateToProps, mapDispatchToProps)(MediaRecorders);
