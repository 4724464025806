import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import isEmpty from "./is-empty";

const useStyles = makeStyles((theme) => ({
  imageView: {
    // display: 'flex',
    // justifyContent: 'center'
  },
  profileImageUploadFile: {
    display: "none",
  },
  largeAvatar: {
    cursor: "pointer",
    textTransform: "capitalize",
    fontSize: 20,
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const PhotoUpload = ({ imageName, imageUrl, onChange, htmlFor, disabled }) => {
  const classes = useStyles();

  return (
    <div className={classes.imageView}>
      <input
        disabled={disabled || false}
        className={classes.profileImageUploadFile}
        accept="image/x-png,image/gif,image/jpeg"
        id={htmlFor ? htmlFor : "contained-button-file"}
        type="file"
        onChange={onChange}
      />
      <label htmlFor={htmlFor ? htmlFor : "contained-button-file"}>
        <Avatar
          alt={imageName}
          src={!isEmpty(imageUrl) ? imageUrl : null}
          className={classes.largeAvatar}
        />
      </label>
    </div>
  );
};

export default PhotoUpload;
