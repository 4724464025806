import { DASHBOARD_LOADING, GET_ASSESSMENT_RESULT, GET_PSYCHOMETRIC_QUESTIONS, GET_PSYCHOMETRIC_RESULT, GET_COUNSELLING_REQUEST } from '../actions/types';

const initialState = {
    loading: false,
    assessmentResult: [],
    psychometricQuestions: [],
    psychometricResult: null,
    comunicationVideoURL: null,
    counsellingRequest: []
};

export default function(state = initialState, action) {
    switch(action.type) {
        case DASHBOARD_LOADING:
            return {
                ...state,
                loading: true
            }
        case GET_ASSESSMENT_RESULT:
            return {
                ...state,
                assessmentResult: action.payload,
                loading: false
            }
        case GET_PSYCHOMETRIC_QUESTIONS:
            return {
                ...state,
                psychometricQuestions: action.payload,
                loading: false
            }
        case GET_PSYCHOMETRIC_RESULT:
            return {
                ...state,
                psychometricResult: action.payload.data.scores,
                comunicationVideoURL: action.payload.data.comunicationVideoURL,
                loading: false
            }
        case GET_COUNSELLING_REQUEST:
            return {
                ...state,
                counsellingRequest: action.payload,
                loading: false
            }
        default:
            return state;
    }
}